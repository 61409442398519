.contactHead{
    color: var(--primary-color);
    transition: 0.3s;
    font-size: 35px;
}
.nestedHead{
    color: var(--text-color)
}
.is-black .contactHead{
    color: var(--invert-text-color);
}
.is-black .nestedHead{
    color: var(--invert-primary-color);
}

  
  .contact {
    display: flex;
    justify-content: center;
    align-items: start;

    transition: 0.5s;
  }
  
  .contact-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 50px;
    padding-bottom: 70px;
    border-radius: 34px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Add a box shadow to create a pop-out effect */
    margin-bottom: 50px;
  }
  .contactForm{
    padding-top: 10px;
  }
  
  .contactForm .submit-button {
    margin-top: 20px;
    background-color: #2e8cff;
    color: white;
    padding: 10px 20px;
    border-radius: 20px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%; /* Set the width to 100% */
    box-sizing: border-box; /* Include padding in the width calculation */
    font-size: 16px;
  }  
  
  .contactForm .submit-button:hover {
    background-color: #1d6fad;
  }

  .error-msg{
    color: white;
    background-color: rgb(255, 24, 24);
    width: 100%;
    text-align: center;
    margin-top: 8px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 5px;
  }

  .success-msg{
    color: white;
    background-color: rgb(0, 180, 0);
    opacity: 0.8;
    width: 100%;
    text-align: center;
    margin-top: 8px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 5px;
  }

.contactForm .text-area{
    font-family: 'Montserrat', sans-serif;
    border-radius: 15px;
    color: var(--text-color);
    transition: 0.3s;

}
.contactForm .labelTag{
    color: var(--text-color);
    transition: 0.3s;
    font-weight: bold;
}

.is-black .contactForm .labelTag{
    color: var(--invert-text-color);
}

.is-black .contactForm .text-area{
    background-color: rgba(255, 0, 0, 0);
    color: var(--invert-text-color);
}
.is-black .contactForm .submit-button{
    background-color: var(--invert-primary-color);
}
.is-black .contactForm .submit-button:hover{
    background-color: #4297ff;
}
.recaptcha-light{
    animation: fadeInAnimation ease 0.3s;

}
.recaptcha-dark{
    display:None;
}
.is-black .recaptcha-light{
    display: none;
}
.is-black .recaptcha-dark{
    animation: fadeInAnimation ease 0.3s;
    display: block;
}
@keyframes fadeInAnimation {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }