.footer {
  width: 100%;
  height: auto;
  background: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 10px;
  transition: 0.3s;
  padding-bottom: 10px;
}

.socialMedia svg {
  color: white;
  margin: 20px;
  font-size: 70px;
  cursor: pointer;
  transition: 0.3s;
}

.footer p {
  color: white;
}
.is-black .footer {
  background: var(--invert-bg-color);
  box-shadow: 0px -1px 20px rgba(0, 0, 0, 0.4);
}

@media only screen and (max-width: 775px) {
  .footer svg {
    font-size: 50px;
  }
}
