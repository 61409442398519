.projects {
  width: 100%;
  min-height: calc(100vh - 74px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
}

.projects .projectTitle {
  text-align: center;
  color: #3e497a;
  font-size: 60px;
  font-weight: bolder;
}

.projectList {
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  place-items: center;
}

.project-title {
  color: var(--text-color);
  transition: 0.3s;
}

.is-black .project-title {
  color: var(--invert-text-color);
}
/* BUTTON STYLING */
.toggle-btn {
  position: relative;
  display: flex;
  width: 350px;
  justify-content: space-around;
  margin-top: 10px;
}

.toggle-btn input {
  display: none;
}

.slider {
  /* position: absolute; */

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--text-color);
  border-radius: 46px;
  transition: all 0.3s ease;
  width: 80px; /* add this line */
  height: 30px;
  opacity: 0.6;
}
.is-black .slider {
  /* position: absolute; */

  background-color: var(--invert-text-color);
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px; /* increase the size of the circle */
  width: 20px; /* increase the size of the circle */
  translate: 5px 5px;
  background-color: white;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.is-black .slider:before {
  background-color: var(--invert-bg-color);
}

input:checked + .slider {
  background-color: var(--primary-color);
  opacity: 1;
}
.is-black input:checked + .slider {
  background-color: var(--invert-primary-color);
}

input:checked + .slider:before {
  transform: translateX(51px); /* increase the distance the circle moves */
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--primary-color);
}

.slider.round {
  border-radius: 46px; /* increase the border-radius */
}

.slider.round:before {
  border-radius: 50%;
}
.code-url {
  display: flex;
  justify-content: center;
  padding-top: 7px;
  width: 100px;
  color: var(--text-color);
  transition: 0.3s;
}
.web-url {
  display: flex;
  justify-content: center;
  padding-top: 7px;
  width: 100px;
  color: var(--text-color);
  transition: 0.3s;
}
.is-black .code-url {
  color: var(--invert-text-color);
}
.is-black .web-url {
  color: var(--invert-text-color);
}

/* MENU ITEM STYLING */

.projectItem {
  border-radius: 15px;
  width: 325px;
  height: 360px;
  margin: 40px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  text-align: center;
  transition: 0.5s ease;
  font-family: Arial, Helvetica, sans-serif;
}
.projects-header {
  color: var(--text-color);
  transition: 0.3s;
  font-size: 2.5em;
}
.is-black .projects-header {
  color: var(--invert-text-color);
}
.projectItem:hover {
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.3);

  cursor: pointer;
  transform: scale(1.05);
}

.projectItem .bgImage {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 100%;
  height: 217px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.projectItem h1 {
  font-size: 25px;
}

.first-word {
  color: var(--primary-color);
  font-weight: bolder;
  transition: 0.3s;
}
.is-black .first-word {
  color: var(--invert-primary-color);
}
.project-description {
  margin-top: 5px;
  margin-bottom: 5px;
  color: #676767;
  transition: 0.3s;
}
.description-header {
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 7px;
}
.is-black .project-description {
  color: #c4c4c4;
}
.skills-list {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  list-style: none;
}
.skills-list li {
  font-size: 14.5px;
}

@media only screen and (max-width: 1300px) {
  .projectList {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 775px) {
  .projectList {
    grid-template-columns: 1fr;
  }
}

.slide-from-left {
  animation: slide-from-left 0.5s ease-out;
}

@keyframes slide-from-left {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
