:root {
  --primary-color: #2e8cff;
  --white-color: #f2f2f2;
  --text-color: #5b5b5b;
  --gray-color: #888888;
  --invert-primary-color: #77b3fc;
  --invert-text-color: #ececec;
  --invert-bg-color: #2b3137;
}
.App {
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: column;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #f2f2f2;
  transition: 0.3s;
}

body {
  padding: 0;
  margin: 0;
}

.is-black .App {
  background-color: #2b3137;
}
.experience {
  min-height: calc(100vh - 74px);
}
.contact {
  min-height: calc(100vh - 74px);
  margin-top: 50px;
}
