/* Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://unpkg.com/css.gg@2.0.0/icons/css/moon.css");
@import url("https://unpkg.com/boxicons@2.1.1/css/boxicons.min.css");

/* Main Styles */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  font-family: Montserrat;
  background: var(--white-color);
  transition: 0.3s;
}

a {
  text-decoration: none;
}

ul > .header-menu__item {
  list-style: none;
}

input[type="text"],
select,
textarea,
input[type="email"] {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  font-size: 16px;
  font: Montserrat;
  resize: vertical;
}

.container {
  max-width: 1100px;
  padding: 0 10px;
  width: 100%;
  margin: 0 auto;
}

.header {
  padding: 23px 0;
  background: var(--white-color);
  box-shadow: 0px -3px 10px rgba(0, 0, 0, 0.4);
  transition: 0.3s;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  z-index: 9999;
}

.header__body {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__logo {
  font-weight: 600;
  font-size: 28px;
  color: var(--text-color);
  transition: 0.3s;
}

.header__rightside {
  display: flex;
  align-items: center;
}

.header-menu__list {
  display: flex;
  align-items: center;
}

.header-menu__item {
  padding: 0 10px;
}

.header-menu__link {
  font-weight: 500;
  font-size: 18px;
  color: var(--text-color);
  display: inline-flex;
  position: relative;
  transition: 0.3s;
}

.header-menu__link:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 0;
  height: 4px;
  background: var(--primary-color);
  border-radius: 2px;
  transition: 0.3s;
}

.header-menu__link:hover:after {
  width: 100%;
}

.header__moon {
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-size: 24px;
  color: var(--text-color);
  cursor: pointer;
  transition: 0.3s;
}

.header__mob-menu {
  margin-left: 10px;
  font-size: 26px;
  color: var(--text-color);
  cursor: pointer;
  transition: 0.3s;
  display: flex;
  align-items: center;
}

.header__mob {
  display: none;
}

.intro {
  min-height: calc(100vh - 74px);
  display: flex;
  align-items: center;
}

.intro__body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0;
}

.intro__content {
  max-width: 500px;
  width: 100%;
  padding-right: 20px;
}

.intro__img {
  max-width: 500px;
  width: 100%;
}

.intro__img img {
  display: block;
  width: 100%;
}

.intro__title {
  font-weight: 600;
  font-size: 64px;
  line-height: 1.2;
  color: var(--text-color);
  margin-bottom: 10px;
  transition: 0.3s;
}

.intro__title span {
  color: var(--primary-color);
  transition: 0.3s;
}
.colored_words {
  color: var(--primary-color);
  transition: 0.3s;
}

.intro__position {
  display: block;
  font-weight: 500;
  font-size: 24px;
  color: var(--gray-color);
  margin-bottom: 25px;
}

.intro__text {
  font-size: 16px;
  line-height: 1.3;
  color: var(--text-color);
  margin-bottom: 30px;
  transition: 0.3s;
}

.btn {
  display: inline-flex;
  background: transparent;
  border: 2px solid var(--primary-color);
  border-radius: 30px;
  padding: 13px 36px;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 16px;
  color: var(--primary-color);
  transition: 0.3s;
}

.btn .bx {
  font-size: 24px;
}

.btn:hover {
  background: var(--primary-color);
  color: var(--white-color);
  transform: scale(1.05);
}

.intro-soc {
  display: flex;
  margin: 0 -10px;
  margin-top: 40px;
  align-items: center;
}

.intro-soc__item {
  padding: 0 10px;
}

.intro-soc__link {
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--primary-color);
  background: transparent;
  font-size: 24px;
  color: var(--primary-color);
  transition: 0.3s;
}

.intro-soc__link:hover {
  background: var(--primary-color);
  color: var(--white-color);
  transform: scale(1.15);
}

.labels {
  color: (--text-color);
  font-size: 18px;
  transition: 0.3s;
}
.text_info {
  font-size: 14px;
  font-family: Montserrat;
  line-height: 1.3;
  color: var(--text-color);
  margin-bottom: 30px;
  transition: 0.3s;
}
.inputs {
  background-color: var(--white-color);
}

.paragraph_box {
  height: 100px;
}
#submit_button {
  font-size: 16px;
  font-weight: bold;
  font-weight: 400;
  padding: 6px 30px;
}

.light_profile_pic {
  animation: fadeInAnimation ease 0.5s;
}
.is-black .dark_profile_pic {
  animation: fadeIn ease 0.5s;
}

/* Black Styles */

body.is-black {
  background: var(--invert-bg-color);
}

.is-black .header {
  background: var(--invert-bg-color);
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.5);
}

.is-black .header__logo {
  color: var(--invert-text-color);
}

.is-black .header-menu__link {
  color: var(--invert-text-color);
}

.is-black .header-menu__link:after {
  background: var(--invert-primary-color);
}

.is-black .header__moon {
  color: var(--invert-text-color);
}

.is-black .intro__title {
  color: var(--invert-text-color);
}

.is-black .intro__title span {
  color: var(--invert-primary-color);
}

.is-black .intro__text {
  color: var(--invert-text-color);
}

.is-black .btn {
  color: var(--invert-primary-color);
  border-color: var(--invert-primary-color);
}

.is-black .btn:hover {
  background: var(--invert-primary-color);
  color: var(--white-color);
}

.is-black .intro-soc__link {
  color: var(--invert-primary-color);
  border-color: var(--invert-primary-color);
}

.is-black .intro-soc__link:hover {
  background: var(--invert-primary-color);
  color: var(--white-color);
}

.is-black .header__mob-menu {
  color: var(--invert-text-color);
}
.is-black .labels {
  color: var(--invert-text-color);
}
.is-black .text_info {
  background-color: var(--invert-bg-color);
  color: var(--invert-text-color);
}

.moon-box {
  margin-top: 2px;
  margin-left: 10px;
  margin-right: 5px;
}
.dm-icon {
  font-size: 25px;
  color: var(--text-color);
  transition: 0.3s;
}

.is-black .dm-icon {
  color: var(--invert-text-color);
}
.dm-icon:hover {
  transform: scale(1.3);
}
.burger {
  font-size: 30px;
}
.first-letter {
  color: var(--primary-color);
}
.is-black .first-letter {
  color: var(--invert-text-color);
}
.is-black .second-letter {
  color: var(--invert-primary-color);
}

/* .is-black .light_profile_pic {
  transition: 0.3s;
  opacity: 0;
} */
/* .is-black .dark_profile_pic {
  transition: 0.3s;
  opacity: 1;
} */
@media (max-width: 1027px) {
  .header-menu__link {
    font-size: 16px;
  }

  .header__logo {
    font-size: 24px;
  }

  .intro__title {
    font-size: 42px;
  }

  .intro__position {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .intro__text {
    font-size: 14px;
    margin-bottom: 20px;
  }

  .intro-soc {
    margin-top: 20px;
  }

  .btn {
    font-size: 14px;
  }

  .btn .bx {
    font-size: 20px;
  }
  .dm-icon {
    font-size: 20px;
  }
}

@media (max-width: 775px) {
  .header {
    padding: 20px 0;
  }

  .intro {
    min-height: calc(100vh - 69px);
  }

  .header-menu {
    position: fixed;
    top: 69px;
    left: 100%;
    width: 100%;
    height: calc(100vh - 69px);
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
  }

  .header-menu.is-active {
    left: 0;
  }

  .header-menu__list {
    flex-direction: column;
  }

  .header__mob {
    display: block;
  }

  .is-black .header-menu {
    background: rgba(0, 0, 0, 0.8);
  }

  .header-menu__item:not(:last-child) {
    margin-bottom: 20px;
  }

  .header-menu__link {
    font-size: 24px;
  }

  .intro__body {
    flex-direction: column-reverse;
  }

  .intro__img {
    margin-bottom: 30px;
  }

  .intro__content {
    padding: 0;
  }

  .intro__title {
    text-align: center;
  }

  .intro__position {
    text-align: center;
  }

  .intro__text {
    text-align: center;
  }

  .intro__btn {
    display: flex;
    justify-content: center;
  }

  .intro-soc {
    justify-content: center;
  }
  .dm-icon {
    font-size: 25px;
  }
  .dark_profile_pic {
    max-width: 400px;
  }
  .light_profile_pic {
    max-width: 400px;
  }
  .intro__img {
    display: flex;
    justify-content: center;
  }
}

.slide-from-left {
  animation: slide-from-left 0.5s ease-in;
}

@keyframes slide-from-left {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
